























import {
  ControlElement,
  JsonFormsRendererRegistryEntry,
  rankWith,
  isStringControl,
} from '@jsonforms/core';
import { defineComponent, ref } from '@vue/composition-api';
import { rendererProps, useJsonFormsControl, RendererProps } from '@jsonforms/vue2';

const InputControlRenderer = defineComponent({
  name: 'input-control-renderer',
  props: {
    ...rendererProps(),
  },
  data: () => ({
    state: false,
  }),
  setup(props: RendererProps<ControlElement>) {
    const input = ref(null);

    return {
      ...useJsonFormsControl(props),
      input,
    }
  },
  mounted() {
    this.validate();
  },
  methods: {
    validate() {
      // @ts-expect-error
      this.state = this.input?.checkValidity() || false;
    },
    onChange(value: string) {
      this.validate();
      this.handleChange(
        this.control.path,
        value,
      );
    }
  },
});
export default InputControlRenderer;
export const entry: JsonFormsRendererRegistryEntry = {
  renderer: InputControlRenderer,
  tester: rankWith(10, isStringControl)
};
