
































































































import Vue, { PropType } from 'vue';

import { VaultItem, VaultMinMeta, VaultPostItem } from 'vaultifier';
import RawData from './RawData.vue';

import FormEditView from './FormEditView.vue';
import RelationsView from './RelationsView.vue';
import HandySignaturForm from './HandySignaturForm.vue';
import Provis from './Provis.vue';

import { ActionType } from '@/store/action-type';
import RawJson from './RawJson.vue';

interface Data {
  isSaving: boolean;
  activeTabIndex: number;
  totalRelations: number;
  hasSignError: boolean;
  isSigning: boolean;
}

export default Vue.extend({
  props: {
    item: Object as PropType<VaultItem>,
    showRawView: {
      default: true,
      type: Boolean as PropType<boolean>,
    },
  },
  data: (): Data => ({
    isSaving: false,
    activeTabIndex: 0,
    totalRelations: 0,
    hasSignError: false,
    isSigning: false,
  }),
  components: {
    RawData,
    FormEditView,
    RelationsView,
    HandySignaturForm,
    RawJson,
    Provis,
  },
  computed: {
    schemaDri(): string | undefined {
      return this.item.schemaDri;
    },
    hasSchema(): boolean {
      return !!this.schemaDri;
    },
    handySignaturLogo(): string {
      return require('../assets/handysign_logo.jpg');
    }
  },
  methods: {
    async saveVaultItem(item: VaultPostItem) {
      this.isSaving = true;

      try {
        await this.$store.dispatch(ActionType.UPDATE_VAULT_ITEM, item);
      } catch {
        /* TODO: Error handling */
      }

      this.isSaving = false;
    },
    selectVaultItem(id: number) {
      this.$emit('selectVaultItem', {
        id,
      } as VaultMinMeta);

      // if new vault item was set through clicking on a relation
      // we want to show the first tab so the user notices something has changed
      this.activeTabIndex = 0;
    },
    async signItem() {
      this.hasSignError = false;
      this.isSigning = true;

      await (this.$refs.handySignaturForm as any).sign();

      // if .sign() fulfills the promise, something went wrong
      // signing always leaves the current browser page that's why the promise will not be resolved, if everything goes according to plan :-)
      this.hasSignError = true;
      this.isSigning = false;
    }
  },
})
